
import Vue, { PropType } from "vue";
import { date } from "@/filters";
import { Building } from "@/interfaces";

export default Vue.extend({
  filters: {
    date
  },
  props: {
    building: {
      type: Object as PropType<Building>,
      required: true
    }
  }
});
